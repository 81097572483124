<template>
  <div class="factoid">
    <div :class="computedClass">
      <i :class="computedIconName"></i>
    </div>
    <h4 class="factoid__header">{{ heading }}</h4>
    <div class="factoid__subheader" v-html="subheading"></div>
  </div>
</template>

<script>
export default {
  name: 'Factoid',
  props: {
    heading: { type: String, required: true },
    subheading: { type: String, required: true },
    /**
     * Icon name from Bootstrap Icons
     * Defaults to circle-half if not specified
     */
    icon: { type: String, default: 'circle-half' },
    /**
     * Icon background color
     * @values red, black, grey, light-grey
     */
    color: { type: String, required: true },
  },
  computed: {
    /**
     * Adds color prop to classes for Icon Wrapper
     */
    computedClass() {
      let col = this.color.toLowerCase();
      return `factoid__iconwrap factoid__iconwrap-${col}`;
    },
    /**
     * Adds icon prop to classes for Icon itself
     */
    computedIconName() {
      let icon = this.icon.toLowerCase();
      return `factoid__icon bi bi-${icon}`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.factoid {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  &__iconwrap {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &-red {
      background-color: $red;
    }
    &-black {
      background-color: $black;
    }
    &-grey,
    &-gray {
      background-color: $grey;
    }
    &-light-grey,
    &-light-gray {
      background-color: $light-grey;
    }
  }
  &__icon {
    font-size: 1rem;
    line-height: 0;
    color: $white;
  }
  &__header {
    @include heading-base;
    font-size: $header-sm;
    margin-bottom: 1rem;
  }
  &__subheader {
    font-size: $text-md;
  }
}
@include media-breakpoint-up(lg) {
  .factoid {
    &__iconwrap {
      height: 80px;
      width: 80px;
    }

    &__icon {
      font-size: 2rem;
      -webkit-text-stroke: 1px white;
    }

    &__header {
      font-size: $header-md;
    }
    &__subheader {
      font-size: $text-lg;
    }
  }
}
</style>
